import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import { H2, H3, MarkdownContent } from "../components/Common";
import Section, { SectionContent } from "../components/sections/Section";
import styled from "styled-components";
import { graphql } from "gatsby";
import ReactHelmet from "react-helmet";
import Header from "src/elements/Header";
import Footer from "src/elements/Footer";
import theme from "src/components/theme";

const Title = styled(H2)`
  width: 100%;
  text-align: center;
  margin: 0 0 40px 0;
`;

const LastModified = styled(H3)`
  width: 100%;
  text-align: center;
  font-weight: normal;
  margin: 0 0 80px 0;
`;

const SectionContainer = styled(Section)`
  background-color: ${theme.lightBackgroundColor};

  ${SectionContent} {
    padding: 40px 16px;
    max-width: 880px;

    @media (min-width: ${theme.mobileBreakpoint}) {
      padding: 80px 40px;
    }
  }
`;

const DocsPageTemplate = ({ title, date, content }) => {
  return (
    <SectionContainer>
      <Title>{title}</Title>
      <LastModified>Last modified {date}</LastModified>
      <MarkdownContent content={content} />
    </SectionContainer>
  );
};

DocsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default function Template({ data }) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data

  return (
    <Layout header={<Header variant="dark" />} footer={<Footer />}>
      <ReactHelmet title={markdownRemark.frontmatter.title} />
      <DocsPageTemplate
        title={markdownRemark.frontmatter.title}
        date={markdownRemark.frontmatter.date}
        content={markdownRemark.html}
      />
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export const pageQuery = graphql`
  query DocByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        title
      }
    }
  }
`;
